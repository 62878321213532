/* You can add global styles to this file, and also import other style files */

@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

table {
  width: 100%;
}

.mat-paginator-page-size {
  display: none!important;
}

.loading-shade-container {
  position: relative;
  min-height: 200px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.mat-paginator {
  margin-top: auto;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center
}

.dialog-loading mat-dialog-container {
  border-radius: 50%;
  padding: 8px;
}

/*
 * Override the width of the table set by Bootstrap content styles.
 * See: https://github.com/ckeditor/ckeditor5-table/issues/154.
 */
.ck-content .table {
  width: auto;
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
}

.saving span {
  /**
   * Use the blink animation, which is defined above
   */
  animation-name: blink;
  /**
   * The animation should take 1.4 seconds
   */
  animation-duration: 1.4s;
  /**
   * It will repeat itself forever
   */
  animation-iteration-count: infinite;
  /**
   * This makes sure that the starting style (opacity: .2)
   * of the animation is applied before the animation starts.
   * Otherwise we would see a short flash or would have
   * to set the default styling of the dots to the same
   * as the animation. Same applies for the ending styles.
   */
  animation-fill-mode: both;
}

.saving span:nth-child(2) {
  /**
   * Starts the animation of the third dot
   * with a delay of .2s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: .2s;
}

.saving span:nth-child(3) {
  /**
   * Starts the animation of the third dot
   * with a delay of .4s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: .4s;
}

.mat-header-cell, .mat-sort-header-container, .mat-cell {
  text-align: center !important;
  justify-content: center;
}

.list-add-button {
  height: 3.4em;
}

.image-list {
  height: auto;
  width: 160px;
  display: inline-block;

  button {
    height: 32px;
    width: 32px;
    padding: 8px;
    right: 0;
    top: 0;
  }

  &--menu {
    height: calc(70vw * 1/3);
    width: 70vw;

    img {
      object-fit: cover;
    }
  }

  .banner-details {
    z-index: 10;
    top: 0;
    left: 0;

    font-size: calc(0.3rem + 1.8vw);

    h1 {
      font-size: 2.5em;
    }

    p {
      font-size: 1em;
    }
  }
}

.image-list-container {
  overflow-x: auto;
  white-space: nowrap;
}

.sentry-error-embed-wrapper {
  z-index: 9999!important;
}
